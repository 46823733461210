import React from "react"

// Gatsby
import { Link } from "gatsby"
import { graphql } from "gatsby"
// Layout
import Layout from "../components/layout"
import { format } from "date-fns"

// Fonts
import "typeface-roboto-mono"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

// Animations
import { motion } from "framer-motion"

export default function BlogPostTemplate({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout flexGrow={false} headerType="standard-no-borders">
      <article>
        <motion.div
          className="article__header"
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          transition={{ type: "spring" }}>
          <div className="container">
            <div className="article__header-content">
              <Link to="/blog">
                <motion.span
                  className="article__back"
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  whileHover={{ x: -15, opacity: 1 }}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: "10px" }}
                  />
                  Retour aux articles
                </motion.span>
              </Link>
              <motion.h1
                className="article__title"
                initial={{ opacity: 0, translateY: 100 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{ type: "spring" }}>
                {frontmatter.title}
              </motion.h1>
              <div className="article__infos">
                <span className="article__info">
                  {format(new Date(frontmatter.date), "MM/dd/yyyy")} - Environ{" "}
                  {markdownRemark.timeToRead} min de lecture
                </span>
              </div>
              {/* <ul className="badges__columns">
                  {frontmatter.tags.map((tag: any, index: number) => (
                    <li className="badge badge--theme" key={index}>{tag}</li>
                  ))}
                </ul> */}
            </div>
          </div>
        </motion.div>
        <div className="article__divider">
          <svg
            data-name="divider"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none">
            <path
              d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
              className="shape-fill"></path>
          </svg>
        </div>
        <div className="article__body">
          <div className="container" style={{ maxWidth: "768px" }}>
            <div
              className="article__content"
              dangerouslySetInnerHTML={{ __html: html }}></div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tags
        date
      }
      html
      timeToRead
    }
  }
`
